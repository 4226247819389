import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'ProjectDashboardPage',
    components: {},
    computed: {
        ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
    }
});
